import '../scss/styles.scss';
import cs001 from './custom_elements/clac_001_scrolltop_button'
import cs002 from './custom_elements/clac_002_scroll_position'
import './custom_elements/clac_004_hero';
import './custom_elements/clac_006_hero_slider';
import './custom_elements/clac_008_card_slider';
import accordion from './module/accordion'
import animation from './module/animation'
import carousel from './module/carousel'
import navigation from './module/navigation'
import custom from './module/custom'
// import cookiebanner from './module/cookiebanner'