/***** rsce_cs_006 *****/
let cs006;
// encapsulate element js
cs006 = function () {
  window.addEventListener("load", function () {
    let scrollDown = document.querySelector(".rsce_cs_006__scroll-down");
    if (!scrollDown) {
      return;
    }

    // add click event
    scrollDown.addEventListener("click", function () {
      let hero = document.querySelector(".rsce_cs_006");
      if (!hero) {
        return;
      }

      window.scrollTo({top: hero.scrollTop + hero.offsetHeight, behavior: "smooth"});
    });
  });
}


// init
cs006();

export default cs006;